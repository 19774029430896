@tailwind base;
@tailwind components;
@tailwind utilities;

input:-webkit-autofill::first-line {
    font-size: 14px !important;
}
input:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #999;
}

.center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/* #main-background {
    background: linear-gradient(45deg, #a69658b1, #f92149e1), #1f1f1fca;
    background-size: cover;
    background-blend-mode: darken;
    background-attachment: fixed;
} */

.gradient-border {
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, rgb(139, 0, 60), rgb(50, 78, 204)) 1;
    border-radius: 5px;
}

.hideIn {
    display: none;
    animation: slideIn 1s ease-in-out forwards;
}
.hideOut {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    animation: slideOut 1s ease-in-out forwards;
}

.hideOut-normal {
    animation: slideOut 1s ease-in-out forwards;
}

@keyframes slideIn {
    0% {
        opacity: 1;
    }
    80% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}
@keyframes slideOut {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0.9;
    }
    100% {
        opacity: 1;
    }
}

* {
    font-family: "Nunito", sans-serif;
}
/* .nunito_font {
  font-family: "Nunito", sans-serif;
} */

.h-dashboard {
    height: 90vh;
}

.nice-back {
    background-image: url("https://www.planetware.com/wpimages/2020/02/france-in-pictures-beautiful-places-to-photograph-eiffel-tower.jpg");
}

.poppins {
    font-family: "Poppins", sans-serif;
}

.hide-scroll {
    scrollbar-width: none;
}
.hide-scroll::-webkit-scrollbar {
    display: none;
}
input[type="radio"] {
    appearance: none;
    width: 16px;
    height: 16px;
    border: 3.5px solid #e8e8e838;
    border-radius: 50%;
    background-clip: content-box;
}

input[type="radio"]:checked {
    background-color: #ff0040;
}

input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    width: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
}
.slider-track {
    width: 100%;
    height: 1px;
    background-color: #d5d5d5;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    border-radius: 5px;
}

input[type="range"]::-webkit-slider-runnable-track {
    appearance: none;
    -webkit-appearance: none;
    height: 5px;
}
input[type="range"]::-moz-range-track {
    appearance: none;
    -moz-appearance: none;
    height: 5px;
}
input[type="range"]::-ms-track {
    appearance: none;
    -moz-appearance: none;
    height: 5px;
}

input[type="range"]::-webkit-slider-thumb {
    margin-top: -5px;
    pointer-events: auto;
}
/* .tableplans {
    background-color: #222;
    box-shadow: inset 0px 2px 4px rgba(0, 255, 255, 0.3), inset 0px 4px 8px rgba(0, 255, 255, 0.3), inset 0px 8px 16px rgba(0, 255, 255, 0.3);
    transition: 0.2s;
    transform: translateY(2px);
} */

.tbody > tr > td {
    transition-duration: 200ms;
}
.tbody > tr:hover {
    background-color: #2d3037;
}
.tbody > tr > td {
    transition-duration: 200ms;
}
.tbody > tr > td:nth-child(1) {
    width: 25%;
    /* position: sticky;
  left: -20px; */
}
.tbody > tr > td:nth-child(2) {
    width: 15%;
}
.tbody > tr > td:nth-child(3) {
    width: 20%;
}
.tbody > tr > td:nth-child(4) {
    width: 20%;
}
.tbody > tr > td:nth-child(5) {
    width: 20%;
}

.table-row-spacer::before {
    content: "";
    display: table-row;
    height: 0.5rem; /* Adjust the height as needed */
}
