@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500&display=swap");
* {
  font-family: "Poppins", sans-serif;
  /* font-weight: 300; */
  /* letter-spacing: 0.025em; */
}

.font-Poppins {
  font-family: "Poppins", sans-serif;
}
.font-work {
  font-family: "Work Sans", monospace;
}

.font-JetBrains {
  font-family: "JetBrains Mono", monospace;
}

.quiz-background {
  background: linear-gradient(-30deg, #ff005c, #fb75a5);
}

.tagline-container {
  width: 100%;
  min-width: 50%;
  max-width: 1200px;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  /* background-image: url("./assets/images/auth.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 1024px) {
  .tagline-container {
    display: none;
  }
}

@media only screen and (max-width: 1440px) {
  .tagline-container {
    min-width: 30%;
  }
}

@media only screen and (max-width: 1024px) {
  .login-container {
    height: 100vh;
    width: 100%;
    max-width: 608px;
    margin: 0 auto;
    padding: 0 16px;
  }
}

.login-container {
  max-width: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media only screen and (max-width: 450px) {
  .login-box {
    width: 100% !important;
    margin: 0px !important;
  }

  .copyright {
    left: 20px !important;
  }
}
@media (min-width: 451px) and (max-width: 1024px) {
  .login-box {
    width: 100% !important;
    margin: 20px !important;
  }
  .copyright {
    left: 40px !important;
  }
}
@media only screen and (max-width: 1440px) {
  .login-box {
    margin: 0 80px;
  }
}

.login-box {
  width: 480px;
  margin: 0 160px 0 80px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.copyright {
  position: absolute;
  left: 80px;
  bottom: 40px;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: grey;
}
.logo {
  position: absolute;
  top: 40px;
  left: 40px;
}
.tagline {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}
.loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #fff;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.thin-scrollbar::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: transparent;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #555;
}

.smooth_shadow {
  box-shadow: 0px 11px 10px rgba(0, 0, 0, 0.1);
}

.spinner-box {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.pulse-container {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #3ff9dc;
}

.pulse-bubble-1 {
  animation: pulse 0.4s ease 0s infinite alternate;
}
.pulse-bubble-2 {
  animation: pulse 0.4s ease 0.1s infinite alternate;
}
.pulse-bubble-3 {
  animation: pulse 0.4s ease 0.2s infinite alternate;
}
.pulse-bubble-4 {
  animation: pulse 0.4s ease 0.3s infinite alternate;
}
.pulse-bubble-5 {
  animation: pulse 0.4s ease 0.4s infinite alternate;
}
@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0.25;
    transform: scale(0.75);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

#loading {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s forwards infinite;
  -webkit-animation: spin 1s forwards infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* tooltip css  */
/* 
  You want a simple and fancy tooltip?
  Just copy all [data-tooltip] blocks:
*/
[data-tooltip] {
  position: relative;
  z-index: 10;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  margin-bottom: 3px;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px); /* 5px is the size of the arrow */
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 300px;
  width: max-content;
  width: -moz-max-content;
  font-size: 14px;
  background-color: #424242;
  background-image: #424242;
  color: #fff;
  letter-spacing: 0.01cm;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, -5px) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: "";
  border-style: solid;
  border-width: 5px 5px 0px 5px; /* CSS triangle */
  border-color: #424242 transparent transparent transparent;
  transition-duration: 0s; /* If the mouse leaves the element, 
                              the transition effects for the 
                              tooltip arrow are "turned off" */
  transform-origin: top; /* Orientation setting for the
                              slide-down effect */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, -5px) scale(1);
}
/* 
  Arrow slide down effect only on mouseenter (NOT on mouseleave)
*/
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}
/*
  That's it for the basic tooltip.

  If you want some adjustability
  here are some orientation settings you can use:
*/

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location="left"]:before,
[data-tooltip-location="left"]:after {
  left: auto;
  right: calc(100% + 5px);
  bottom: 50%;
}

/* Tooltip */
[data-tooltip-location="left"]:before {
  transform: translate(-5px, 50%) scale(0.5);
}
[data-tooltip-location="left"]:hover:before {
  transform: translate(-5px, 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]:after {
  border-width: 5px 0px 5px 5px;
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="left"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* RIGHT */
[data-tooltip-location="right"]:before,
[data-tooltip-location="right"]:after {
  left: calc(100% + 5px);
  bottom: 50%;
}

[data-tooltip-location="right"]:before {
  transform: translate(5px, 50%) scale(0.5);
}
[data-tooltip-location="right"]:hover:before {
  transform: translate(5px, 50%) scale(1);
}

[data-tooltip-location="right"]:after {
  border-width: 5px 5px 5px 0px;
  border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="right"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* BOTTOM */
[data-tooltip-location="bottom"]:before,
[data-tooltip-location="bottom"]:after {
  top: calc(100% + 5px);
  bottom: auto;
}

[data-tooltip-location="bottom"]:before {
  transform: translate(-50%, 5px) scale(0.5);
}
[data-tooltip-location="bottom"]:hover:before {
  transform: translate(-50%, 5px) scale(1);
}

[data-tooltip-location="bottom"]:after {
  border-width: 0px 5px 5px 5px;
  border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
  transform-origin: bottom;
}

[data-tooltip]:after {
  bottom: calc(100% + 3px);
}
[data-tooltip]:after {
  border-width: 7px 7px 0px 7px;
}
/* tooltip css end */

.gradient-border-no {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  height: 44px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 30px;
  background-image: linear-gradient(135deg, #a060d8, #ff9a58);
}

.gradient-inside {
  width: 96px;
  height: 41px;
}

.icon-number {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 30px;
  background-color: #1c202b;
  font-size: 1.25rem;
  line-height: 1.125rem;
  font-weight: 500;
  text-align: center;
}

.btn-secondary {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  --tw-shadow-color: rgba(0, 0, 0, 0.3);
  --tw-shadow: var(--tw-shadow-colored);
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  color: rgb(0 0 0 / var(--tw-text-opacity));
  font-size: 15px;
  padding: 0.75rem 1.5rem;
}

.btn {
  align-items: center;
  border-radius: 0.75rem;
  display: flex;
  flex: none;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1.5rem;
  padding: 0.75rem 1.5rem;
  transition-duration: 0.2s;
}

.cool-link {
  display: inline-block;
  /* color: rgb(216, 216, 216); */
  text-decoration: none;
}

.cool-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: currentColor;
  transition: width 0.3s;
  text-decoration: none;
}

.cool-link:hover::after {
  width: 100%;
  transition: width 0.3s;
  text-decoration: none;
}

.mobile_view {
  height: -webkit-fill-available;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.draggable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.apexcharts-legend-text {
  fill: #ffffff !important;
  color: white !important;
}

.apexcharts-yaxis-label {
  color: white !important;
}

/*Checkboxes styles*/
input[type="checkbox"] {
  display: none;
  appearance: none;
  outline: none;
}

input[type="checkbox"] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  color: currentColor;
  cursor: pointer;
  appearance: none;
  outline: none;
}

input[type="checkbox"] + label:last-child {
  margin-bottom: 0;
  appearance: none;
  outline: none;
}

input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  border: 2px solid #a3a3a3;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.6;
  -webkit-transition:
    all 0.12s,
    border-color 0.08s;
  transition:
    all 0.12s,
    border-color 0.08s;
  appearance: none;
  outline: none;
}

input[type="checkbox"]:checked + label:before {
  width: 15px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  border: 2px solid #05a1e4;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  appearance: none;
  outline: none;
}

/*End Checkboxes styles*/

.Toastify__close-button--light {
  color: #ffffff !important;
  opacity: 1 !important;
  transform: scale(1.1);
}

.hJsDPI {
  /* width: 100%; */
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(6px);
  border-radius: 8px;
  max-height: 150px;
  z-index: 10;
  margin-bottom: 0px;
  cursor: pointer;
  height: 120px;
  position: relative;
}
.hJsDPI_max {
  width: 40px;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(6px);
  border-radius: 8px;
  max-height: 40px;
  z-index: 10;
  margin-bottom: 0px;
  cursor: pointer;
  height: 40px;
  position: relative;
}

.ugcCard {
  opacity: 0;
  transform: translateY(80px);
  transition:
    opacity 0.5s ease,
    transform 1s ease;
}

.growthCard {
  opacity: 0;
  transform: translateY(80px);
  transition:
    opacity 0.5s ease,
    transform 1s ease;
}

.fade-slide-up {
  opacity: 1;
  transform: translateY(0);
}

.fade {
  animation: fadeout forwards 300ms ease-in;
}

@keyframes fadeout {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes slideToLeft {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-to-left {
  animation: slideToLeft 1.5s ease-out;
}
@keyframes slideToRight {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-to-right {
  animation: slideToRight 1.5s ease-out;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-80%);
  }
}
.trusted-slider {
  background: #101010 !important;
  height: 200px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 90%;
}
.trusted-slider::before,
.trusted-slider::after {
  background: linear-gradient(
    to right,
    rgb(16, 16, 16) 0%,
    rgba(16, 16, 16, 0) 100%
  );
  content: "";
  height: 200px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.trusted-slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.trusted-slider::before {
  left: 0;
  top: 0;
}
.trusted-slider > .trusted-slide-track {
  animation: scroll 60s linear alternate infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: max-content;
}
.trusted-slider > .trusted-slide {
  height: 200px;
}
.trusted-slide > img {
  height: 200px;
  object-fit: contain;
}
.image-container {
  position: relative;
  width: 300px; /* Adjust the width to fit your images */
  height: 200px; /* Adjust the height to fit your images */
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: slideIn 1s forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-right {
  transform: translateX(20%);
  opacity: 0;
}

.slide-left {
  transform: translateX(-20%);
  opacity: 0;
}

.slide-right.slide-enter-active,
.slide-left.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition:
    transform 500ms ease-out,
    opacity 500ms ease-out;
}

.homepage-hero-text-card {
  background: rgba(255, 255, 255, 0.72);
  box-shadow: 9px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 29px;
}
@keyframes hero-slide {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, 50%);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.hero-animate-slide {
  animation: hero-slide 0.5s ease-in-out forwards;
}
.owl-carousel {
  display: flex;
  overflow: hidden;
  position: relative;
}

.img-wrapper {
  opacity: 0;
  transform: scale(0.6);
  transition: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-wrapper.big {
  opacity: 1;
  transform: scale(0.8);
  z-index: 0;
  transition: 0.3s;
}

.owl-item.active {
  z-index: 10;
}

.carousel {
  display: flex;
  overflow: hidden;
  position: relative;
}

.carousel-item {
  opacity: 0;
  transform: scale(0.6);
  transition: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 33.33%; /* Adjust the width based on the number of visible images */
  height: 100%;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-item.active {
  opacity: 1;
  transform: scale(0.8);
  z-index: 0;
}

.signup-btn-hover:hover {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 1);
}

.custom-scrollbar-hide::-webkit-scrollbar {
  width: 0rem;
}

.custom-scrollbar-hide::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: transparent;
}
@media (min-width: 1280px) and (max-width: 1550px) {
  .click_preview {
    top: -2rem !important;
    left: -14rem !important;
  }
  .shopable_click_preview {
    top: -2rem !important;
    right: -13rem !important;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  .click_preview {
    top: -2rem !important;
    left: -12rem !important;
  }
  .shopable_click_preview {
    top: -2rem !important;
    right: -10rem !important;
  }
}
.click_preview {
  top: -2rem;
  left: -15rem;
}
.shopable_click_preview {
  top: -2rem;
  right: -14rem;
}
.custom-swiper-wrapper {
  display: flex;
  align-items: flex-start;
}

.custom-swiper-slide {
  min-width: 0; /* Ensure the slide content doesn't overflow */
  flex-shrink: 0;
}

.featured-slider {
  background: transparent !important;
  /* height: 100px; */
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.featured-slider::before,
.featured-slider::after {
  /* background: linear-gradient(to right, rgb(16, 16, 16) 0%, rgba(16, 16, 16, 0) 100%); */
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.featured-slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.featured-slider::before {
  left: 0;
  top: 0;
}
.featured-slider .featured-slide-track {
  animation: scroll 30s linear infinite;
  display: flex;
  flex-grow: 1;
  width: calc(500px * 22);
}
.logos {
  background-color: white;
  padding: 1rem;
  overflow: hidden;
}
.logos-slide {
  white-space: nowrap;
  display: flex;
  align-items: center;
  animation: 8s logoslide infinite linear;
}

.logos-slide img {
  max-width: 7em;
  margin: 0px 40px;
}

@keyframes logoslide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
